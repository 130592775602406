
















import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import { FilterPreviewId } from "@/shared/models";
import { delay } from "lodash";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";

@Component
export default class CustomSelect extends Vue {
  @Prop({ default: true }) isAttached!: boolean;
  @Prop() filterPreviewId?: FilterPreviewId;

  @Ref("select") readonly select!: VueAutocomplete;

  holderElement: HTMLElement | null = null;
  eager = false;
  top = true;

  get clickedFilterPreviewId(): FilterPreviewId {
    return this.$store.state.reportStore.clickedFilterPreviewId;
  }

  @Watch("clickedFilterPreviewId")
  watchPreviewClick(previewId: FilterPreviewId) {
    if (previewId !== this.filterPreviewId) {
      return;
    }

    delay(() => {
      this.select.focus();
      this.select.activateMenu();
      this.$store.commit("setClickedFilterPreviewId");
    }, 100);
  }

  mounted() {
    this.holderElement = this.select.$el as HTMLElement;
    this.eager = true;
  }

  onFocus(event: Event) {
    if (!this.isAttached) {
      return;
    }

    const targetElement = event.target as HTMLElement;

    this.top =
      targetElement.getBoundingClientRect().top +
        targetElement.offsetHeight / 2 >
      window.innerHeight / 2;
  }
}
