





























import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import RangeFilter from "./RangeFilter.vue";
import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import {
  EventOperation,
  FilterId,
  RangeModel,
  SessionNumberModel,
  SessionNumberPeriod,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    RangeFilter,
    CustomAutocomplete,
  },
})
export default class SessionNumberFilter extends Vue {
  @Prop() value!: SessionNumberModel;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop() filterId!: FilterId;

  readonly sessionNumberPeriods = Object.values(SessionNumberPeriod).map(
    (value) => ({
      name: this.$lang(
        `shared.filters.sessionNumberPeriod.${value.toString().toLowerCase()}`
      ),
      value,
    })
  );
  readonly excludedOperations = [EventOperation.LIKE, EventOperation.NOT_LIKE];
  readonly rules = [ValidUtil.required(this.$lang("validation.required"))];

  get localValue(): SessionNumberModel {
    return this.value;
  }

  get localValuePeriod(): SessionNumberPeriod {
    return this.localValue.period;
  }

  set localValuePeriod(value: SessionNumberPeriod) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.period = value;
    this.$emit("input", clonedLocalValue);
  }

  get localValueRange(): RangeModel {
    return this.localValue.range;
  }

  set localValueRange(value: RangeModel) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.range = value;
    this.$emit("input", clonedLocalValue);
  }
}
