

































































import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import { cloneDeep, uniq } from "lodash";

import MultiTextField from "@/shared/components/MultiTextField.vue";
import { EventOperation, EventOperations } from "@/shared/models/PickerModel";
import { FilterId, RangeModel } from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    MultiTextField,
  },
})
export default class RangeFilter extends Vue {
  @Prop() value!: RangeModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: () => [] }) excludedOperations!: Array<EventOperation>;
  @Prop() filterId!: FilterId;
  @Prop({ default: false }) countable!: boolean;

  @Ref("form") readonly form!: VueForm;

  searchMultiValue = "";

  get paramsErrorMessages(): Array<string> {
    if (!this.readonly && !this.localValue.params.length) {
      return [this.$lang("validation.required")];
    } else if (
      !this.readonly &&
      this.isTypeNumber &&
      this.localValue.params.find((item) => isNaN(Number(item)))
    ) {
      return [this.$lang("validation.number")];
    } else if (
      !this.readonly &&
      this.isTypeNumber &&
      this.localValue.params.some((item) => Number(item) < 0)
    ) {
      return [this.$lang("validation.min", 0)];
    }

    return [];
  }

  get firstValueErrorMessages(): Array<string> {
    if (
      !this.readonly &&
      this.localValue.params.length >= 1 &&
      (this.localFirstParamValue === "" || this.localFirstParamValue === null)
    ) {
      return [this.$lang("validation.required")];
    } else if (!this.readonly && this.isTypeNumber) {
      if (Number(this.localFirstParamValue) < 0) {
        return [this.$lang("validation.min", 0)];
      } else if (
        Number(this.localFirstParamValue) > Number(this.localSecondParamValue)
      ) {
        return [this.$lang("validation.lessThanSecondValue")];
      }
    }

    return [];
  }

  get secondValueErrorMessages(): Array<string> {
    if (
      !this.readonly &&
      this.localValue.isTwoValue &&
      this.localValue.params.length === 2 &&
      (this.localSecondParamValue === "" || this.localSecondParamValue === null)
    ) {
      return [this.$lang("validation.required")];
    }

    return [];
  }

  get operations(): Array<Record<string, string>> {
    return EventOperations.reduce((result, value) => {
      if (this.excludedOperations.includes(value)) {
        return result;
      }

      return [
        ...result,
        {
          value,
          text: this.$lang(`shared.eventName.${value.toLowerCase()}`),
        },
      ];
    }, [] as Array<Record<string, string>>);
  }

  get localValue(): RangeModel {
    return RangeModel.of(this.value);
  }

  get localValueOperator(): EventOperation {
    return this.localValue.operator;
  }

  set localValueOperator(value: EventOperation) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.operator = value;
    this.$emit("input", clonedLocalValue);
  }

  get localValueParams(): Array<number | string> {
    return this.localValue.params;
  }

  set localValueParams(value: Array<number | string>) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.params = value;
    this.$emit("input", clonedLocalValue);
  }

  get localFirstParamValue(): string | number {
    return this.localValue.params[0];
  }

  set localFirstParamValue(value: string | number) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.params[0] =
      this.localValue.isFloat && value !== "" && value !== null
        ? Number(value)
        : value;
    this.$emit("input", clonedLocalValue);
  }

  get localSecondParamValue(): string | number {
    return this.localValue.params[1];
  }

  set localSecondParamValue(value: string | number) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.params[1] = value;
    this.$emit("input", clonedLocalValue);
  }

  get isTypeNumber(): boolean {
    return (
      this.localValue.isFloat ||
      this.filterId === FilterId.SESSION_NUMBER ||
      this.filterId === FilterId.USER_AGE ||
      this.filterId === FilterId.SUBSCRIPTION_RENEWAL_COUNT ||
      (this.filterId === FilterId.RANGE_BASED_MULTIPLE_EVENT && this.countable)
    );
  }

  get label(): string {
    return this.filterId === FilterId.USER_AGE
      ? this.$lang("shared.eventName.day")
      : this.$lang("shared.eventName.value");
  }

  @Watch("localValueOperator")
  watchLocalValueOperator() {
    if (
      this.localValue.isMultiValue &&
      this.localValueParams.length === 2 &&
      this.localValueParams.length !== uniq(this.localValueParams).length
    ) {
      this.localValueParams = [
        this.localFirstParamValue ? String(this.localFirstParamValue) : "0",
      ];
    } else if (!this.localValue.isFloat && this.localValue.isSingleValue) {
      this.localValueParams = [
        this.localFirstParamValue ? String(this.localFirstParamValue) : "0",
      ];
    } else if (this.localValue.isTwoValue) {
      this.localValueParams = [
        this.localFirstParamValue && !isNaN(Number(this.localFirstParamValue))
          ? Number(this.localFirstParamValue)
          : 0,
        this.localSecondParamValue && !isNaN(Number(this.localSecondParamValue))
          ? Number(this.localSecondParamValue)
          : 0,
      ];
    } else if (this.localValue.isFloat && this.localValue.isSingleValue) {
      this.localValueParams = [
        this.localFirstParamValue && !isNaN(Number(this.localFirstParamValue))
          ? this.localFirstParamValue
          : 0,
      ];
    }
  }

  addValue() {
    if (this.searchMultiValue && this.searchMultiValue.trim().length > 0) {
      this.localValueParams = [
        ...this.localValueParams,
        this.searchMultiValue,
      ] as Array<string> | Array<number>;
    }

    this.searchMultiValue = "";
  }

  changeValue(value: Array<string>) {
    this.localValueParams = value.filter((it) => it && it.trim().length > 0);
  }
}
