




























import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import RangeFilter from "./RangeFilter.vue";
import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import {
  AdRevenueFilterModel,
  AdRevenueMethod,
  EventOperation,
  RangeModel,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    RangeFilter,
    CustomAutocomplete,
  },
})
export default class AdRevenueFilter extends Vue {
  @Prop() value!: AdRevenueFilterModel;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;

  readonly revenueMethods = Object.values(AdRevenueMethod).map((value) => ({
    name: this.$lang(
      `shared.filters.adRevenueMethod.${value.toString().toLowerCase()}`
    ),
    value,
  }));
  readonly excludedOperations = [
    EventOperation.LIKE,
    EventOperation.NOT_LIKE,
    EventOperation.IN,
    EventOperation.NOT_IN,
  ];
  readonly rules = [ValidUtil.required(this.$lang("validation.required"))];

  get localValue(): AdRevenueFilterModel {
    return this.value;
  }

  get localValueRevenueMethod(): AdRevenueMethod {
    return this.localValue.revenueMethod;
  }

  set localValueRevenueMethod(value: AdRevenueMethod) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.revenueMethod = value;
    this.$emit("input", clonedLocalValue);
  }

  get localValueRange(): RangeModel {
    return this.localValue.range;
  }

  set localValueRange(value: RangeModel) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.range = value;
    this.$emit("input", clonedLocalValue);
  }
}
