
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import TrackerPicker from "@/shared/components/pickers/TrackerPicker.vue";
import {
  DictionaryType,
  TrackerFilterModel,
  TrackerFilterPartModel,
  TrackerPickerModel,
  ValidationRule,
  FilterPreviewId,
  TrackerOrigin,
} from "@/shared/models";

class TrackerLocalFilterModel {
  tracker: TrackerPickerModel;
  valid: boolean;

  constructor(tracker: TrackerPickerModel, valid = false) {
    this.tracker = tracker;
    this.valid = valid;
  }

  static of(model: TrackerFilterModel): TrackerLocalFilterModel {
    return new TrackerLocalFilterModel(
      new TrackerPickerModel({
        source: model.source?.values,
        includedSource: model.source?.included,
        subSource: model.subSource?.values,
        includedSubSource: model.subSource?.included,
        campaign: model.campaign?.values,
        includedCampaign: model.campaign?.included,
        adSet: model.adSet?.values,
        includedAdSet: model.adSet?.included,
        creative: model.creative?.values,
        includedCreative: model.creative?.included,
        publisher: model.publisher?.values,
        includedPublisher: model.publisher?.included,
      } as any as Record<string, any>),
      model.valid
    );
  }
}

@Component({
  components: {
    TrackerPicker,
  },
})
export default class TrackerFilter extends Vue {
  @Prop() value!: TrackerFilterModel;
  @Prop() label!: string;
  @Prop() dataTypes!: Array<DictionaryType>;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: true }) validate!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: true }) hasSubSource!: boolean;
  @Prop() applicationId?: string;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;
  @Prop() origins?: Array<TrackerOrigin>;

  localFilter: TrackerLocalFilterModel = TrackerLocalFilterModel.of(this.value);

  get valid(): boolean {
    return !!this.localFilter.tracker[DictionaryType.SOURCES].length;
  }

  @Watch("value")
  private watchValue(value: TrackerFilterModel) {
    this.localFilter = TrackerLocalFilterModel.of(value);
  }

  @Watch("localFilter", { deep: true })
  private watchLocalFilter(localFilter: TrackerLocalFilterModel) {
    const filter = new TrackerFilterModel(
      this.value.id,
      this.value.required,
      this.value.filled,
      this.localFilter.valid,
      localFilter.tracker[DictionaryType.SOURCES].length
        ? new TrackerFilterPartModel(
            localFilter.tracker.included[DictionaryType.SOURCES],
            localFilter.tracker[DictionaryType.SOURCES]
          )
        : undefined,
      localFilter.tracker[DictionaryType.SUB_SOURCES].length
        ? new TrackerFilterPartModel(
            localFilter.tracker.included[DictionaryType.SUB_SOURCES],
            localFilter.tracker[DictionaryType.SUB_SOURCES]
          )
        : undefined,
      localFilter.tracker[DictionaryType.CAMPAIGNS].length
        ? new TrackerFilterPartModel(
            localFilter.tracker.included[DictionaryType.CAMPAIGNS],
            localFilter.tracker[DictionaryType.CAMPAIGNS]
          )
        : undefined,
      localFilter.tracker[DictionaryType.AD_SETS].length
        ? new TrackerFilterPartModel(
            localFilter.tracker.included[DictionaryType.AD_SETS],
            localFilter.tracker[DictionaryType.AD_SETS]
          )
        : undefined,
      localFilter.tracker[DictionaryType.CREATIVES].length
        ? new TrackerFilterPartModel(
            localFilter.tracker.included[DictionaryType.CREATIVES],
            localFilter.tracker[DictionaryType.CREATIVES]
          )
        : undefined,
      localFilter.tracker[DictionaryType.PUBLISHERS].length
        ? new TrackerFilterPartModel(
            localFilter.tracker.included[DictionaryType.PUBLISHERS],
            localFilter.tracker[DictionaryType.PUBLISHERS]
          )
        : undefined
    );

    if (!this.readonly) {
      this.$emit(
        "input",
        Object.assign(this.value, filter, {
          valid: this.validate ? this.localFilter.valid : this.value.valid,
        })
      );
    }
  }

  @Watch("valid", { immediate: true })
  private watchValid(valid: boolean) {
    this.localFilter.valid = this.validate ? valid : this.value.valid;
  }
}
