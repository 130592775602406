






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { ValidationRule } from "@/shared/models";

@Component
export default class MultiTextField extends Vue {
  @Prop() value!: Array<string>;
  @Prop() label!: string;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) clearable!: boolean;
  @Prop({ default: false }) hideDetails!: boolean;
  @Prop({ default: false }) useParentRules!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;

  localValue: Array<string> = [];
  searchMultiValue = "";

  get localRules() {
    const rules = this.rules;
    return rules
      ? [
          function (values: Array<string>) {
            for (let i = 0; i < rules.length; i++) {
              for (let j = 0; j < values.length; j++) {
                const valid = rules[i](values[j]);
                if (valid === false || typeof valid === "string") {
                  return valid;
                }
              }
            }
            return true;
          },
        ]
      : undefined;
  }

  @Watch("value", { immediate: true })
  private watchValue(value: Array<string>) {
    this.localValue = value;
  }

  @Watch("localValue")
  private watchLocalValue(value: Array<string>) {
    this.$emit("input", value);
  }

  addValue() {
    if (this.searchMultiValue && this.searchMultiValue.trim().length > 0) {
      this.localValue.push(this.searchMultiValue);
    }
    this.searchMultiValue = "";
  }

  changeValue(value: Array<string>) {
    this.localValue = value.filter((it) => it && it.trim().length > 0);
  }
}
