







































import { cloneDeep, debounce } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import RangeFilter from "@/shared/components/filters/RangeFilter.vue";
import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";
import {
  Dictionary,
  DictionaryModel,
  DictionaryType,
  EventPickerParamRangeModel,
  FilterId,
  FilterPreviewId,
  RangeModel,
  ValidationRule,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    CustomAutocomplete,
    RangeFilter,
    PickerDictionaryNotification,
  },
})
export default class EventParamPickerRange extends Vue {
  @Prop() value!: EventPickerParamRangeModel;
  @Prop() eventName!: string;
  @Prop() takenParams!: Array<string>;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop() filterId?: FilterId;
  @Prop({ default: false }) isRequiredParams!: boolean;

  localValueRange: RangeModel | null = null;
  localDictionary = new DictionaryModel();
  isLoading = false;
  search: string | null = null;
  currentSearch: string | null = null;

  get paramRules(): Array<ValidationRule> {
    return this.isRequiredParams
      ? [ValidUtil.required(this.$lang("validation.required"))]
      : [];
  }

  get localValue(): EventPickerParamRangeModel {
    return this.value;
  }

  get localValueParam(): string | undefined {
    return this.localValue.param;
  }

  set localValueParam(value: string | undefined) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.param = value;

    if (value !== null) {
      this.localValueRange = new RangeModel();
    } else {
      this.localValueRange = null;
    }

    this.$emit("input", clonedLocalValue);
  }

  get localFilterPreviewId(): string | undefined {
    return this.filterPreviewId ? `${this.filterPreviewId}_PARAM` : undefined;
  }

  get items(): Array<Dictionary> {
    if (!this.localDictionary) {
      return [];
    }

    return this.localDictionary.values.filter(
      ({ value }) =>
        value === this.value.param || !this.takenParams.includes(value)
    );
  }

  get isFullResult(): boolean {
    return this.localDictionary?.isFullResult ?? true;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  @Watch("eventName", { immediate: true })
  watchEventName() {
    if (!this.eventName) {
      this.localDictionary = new DictionaryModel();

      return;
    }

    this.updateLocalDictionary();
  }

  @Watch("localValueRange")
  watchLocalValueRange(value: RangeModel) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.value = value;
    this.$emit("input", clonedLocalValue);
  }

  @Watch("localValueParam", { immediate: true })
  setUsedDictionaryValue(newValue: string | undefined) {
    if (!newValue) {
      return;
    }

    this.localDictionary.updateUsedValues(
      this.items.filter(({ value }) => newValue === value)
    );
  }

  @Watch("search")
  watchSearch = debounce(this.initSearch, 500);

  mounted() {
    this.localValueRange = RangeModel.of(this.value.value);
  }

  onSearchChange(value: string | null) {
    this.search = value;
  }

  initSearch(search: string | null) {
    if (
      (this.isFullResult && !this.currentSearch) ||
      this.currentSearch === search ||
      (!this.currentSearch && (!search || search.length < 2))
    ) {
      return;
    }

    this.currentSearch = search && search.length > 1 ? search : null;
    this.updateLocalDictionary();
  }

  async updateLocalDictionary() {
    this.isLoading = true;

    try {
      const { dictionaryValues, isFullResult } = await this.$store.dispatch(
        "loadDictionary",
        {
          app: this.applicationId,
          type: DictionaryType.EVENT_PARAM_KEYS,
          parentValues: [this.eventName],
          search: this.currentSearch,
          isForLocal: true,
          searchLimit: -1,
        }
      );

      this.localDictionary.update(
        Dictionary.ofArray(dictionaryValues),
        isFullResult
      );
    } finally {
      this.isLoading = false;
    }
  }
}
