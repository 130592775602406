










































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import EventRangePicker from "@/shared/components/pickers/EventRangePicker.vue";
import {
  DictionaryType,
  EventCombination,
  EventOperation,
  EventRangePickerModel,
  RangeBasedMultipleEventParamFilterModel,
  RangeModel,
  ValidationRule,
  EventParamRangeModel,
  EventPickerParamRangeModel,
  RangeBasedMultipleEventParamFilterValueModel,
  FilterId,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    CustomAutocomplete,
    DatesPicker,
    EventRangePicker,
  },
})
export default class RangeBasedMultipleEventParamFilter extends Vue {
  @Prop() value!: RangeBasedMultipleEventParamFilterModel;
  @Prop() applicationId!: string;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop() dataTypes!: Array<DictionaryType>;
  @Prop() rules?: Array<ValidationRule>;
  @Prop() filterId!: FilterId;

  readonly combinations = Object.values(EventCombination).map((value) => {
    return {
      name: this.$lang(
        `shared.filters.eventCombinations.${value.toString().toLowerCase()}`
      ),
      value,
    };
  });
  readonly excludedOperations = [EventOperation.LIKE, EventOperation.NOT_LIKE];
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  localValueEvents: Array<EventRangePickerModel> = [];

  get localValue(): RangeBasedMultipleEventParamFilterModel {
    return this.value;
  }

  get localValueCombination(): EventCombination {
    return this.localValue.combination;
  }

  set localValueCombination(value: EventCombination) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.combination = value;
    this.$emit("input", clonedLocalValue);
  }

  get localValueDateRange(): Array<string | null> {
    return [this.localValue.from, this.localValue.to];
  }

  set localValueDateRange(value: Array<string | null>) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.from = value[0] ?? "";
    clonedLocalValue.to = value[1] ?? "";
    this.$emit("input", clonedLocalValue);
  }

  get localValueValues(): Array<EventRangePickerModel> {
    return this.localValue.values.length
      ? this.localValue.values.map((value) => {
          const params = value.params?.map(
            (param) => new EventPickerParamRangeModel(param.value, param.name)
          );

          return new EventRangePickerModel(
            value.included,
            params,
            value.name,
            undefined,
            value.range as RangeModel
          );
        })
      : [new EventRangePickerModel()];
  }

  @Watch("localValueEvents")
  watchLocalValueEvents(value: Array<EventRangePickerModel>) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.values = value.map(
      (item) =>
        new RangeBasedMultipleEventParamFilterValueModel(
          item.name,
          item.range
            ? new RangeModel(item.range.operator, item.range.params)
            : undefined,
          item.params
            ? item.params
                .filter((param) => !!param.param)
                .map(
                  (param) => new EventParamRangeModel(param.param, param.value)
                )
            : undefined,
          item.included
        )
    );
    this.$emit("input", clonedLocalValue);
  }

  created() {
    const dictionaryTypesToLoad = this.dataTypes.filter(
      (type) => this.$store.state.dictionaryStore[type].unfilled
    );

    if (dictionaryTypesToLoad.length) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: dictionaryTypesToLoad,
      });
    }
  }

  mounted() {
    this.localValueEvents = [...this.localValueValues];
  }

  addEvent() {
    this.localValueEvents = [
      ...this.localValueEvents,
      new EventRangePickerModel(),
    ];
  }

  removeEvent(key: string | null) {
    this.localValueEvents = this.localValueEvents.filter(
      (event) => event.key !== key
    );
  }
}
