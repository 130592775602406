
































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import DatesFilter from "./DatesFilter.vue";
import {
  AttributionDateFilterModel,
  Dictionary,
  DictionaryType,
  FilterPreviewId,
  ValidationRule,
} from "@/shared/models";

@Component({ components: { DatesFilter, CustomAutocomplete } })
export default class AttributionDateFilter extends Vue {
  @Prop() applicationId!: string;
  @Prop() value!: AttributionDateFilterModel;
  @Prop() label!: string;
  @Prop() dataTypes!: Array<DictionaryType>;
  @Prop({ default: true }) loadData!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop({ default: false }) isCalledFilters!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;

  readonly FilterPreviewId = FilterPreviewId;
  localFilter: AttributionDateFilterModel = AttributionDateFilterModel.of(
    this.value
  );
  dataType: DictionaryType = this.dataTypes[0];

  get dictionaryData(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[this.dataType].values;
  }

  get valid(): boolean {
    return (
      !!this.localFilter.from &&
      !!this.localFilter.to &&
      !!this.localFilter.attributionStatuses &&
      this.localFilter.attributionStatuses.length > 0
    );
  }

  @Watch("value", { immediate: true })
  private watchValue(value: AttributionDateFilterModel) {
    this.localFilter = AttributionDateFilterModel.of(value);
  }

  @Watch("localFilter", { deep: true })
  private watchLocalFilter(localFilter: AttributionDateFilterModel) {
    if (!this.readonly) {
      this.$emit(
        "input",
        Object.assign(this.value, localFilter, {
          valid: this.valid,
        })
      );
    }
  }

  @Watch("valid", { immediate: true })
  private watchValid(valid: boolean) {
    this.localFilter.valid = valid;
  }

  @Watch("dictionaryData", { immediate: true })
  private watchDictionaryData() {
    if (
      this.localFilter.filled &&
      !this.readonly &&
      !this.localFilter.attributionStatuses.length
    ) {
      this.localFilter.attributionStatuses = this.dictionaryData.map(
        (it) => it.value
      );
    }
  }

  created() {
    if (this.loadData && this.dataType && this.dictionaryData.length === 0) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: [this.dataType],
      });
    }
  }
}
