


















































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import IncludedIcon from "@/shared/components/IncludedIcon.vue";
import RangeFilter from "@/shared/components/filters/RangeFilter.vue";
import EventParamPickerRange from "@/shared/components/pickers/EventParamPickerRange.vue";
import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";
import {
  EventRangePickerModel,
  Dictionary,
  EventOperation,
  RangeModel,
  ValidationRule,
  EventPickerParamRangeModel,
  FilterPreviewId,
  FilterId,
  DictionaryType,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import DictionaryLoadMixin from "@/shared/mixins/DictionaryLoadMixin";

@Component({
  components: {
    CustomAutocomplete,
    IncludedIcon,
    RangeFilter,
    EventParamPickerRange,
    PickerDictionaryNotification,
  },
})
export default class EventRangePicker extends mixins(DictionaryLoadMixin) {
  @Prop() value!: EventRangePickerModel;
  @Prop({ default: false }) countable!: boolean;
  @Prop({ default: false }) multipleParams!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: true }) excludable!: boolean;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: true }) validation!: boolean;
  @Prop({ default: false }) hasPrompt!: boolean;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop() excludedOperations!: Array<EventOperation>;
  @Prop() rules?: Array<ValidationRule>;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;
  @Prop({ default: false }) withoutParams!: boolean;
  @Prop() filterId!: FilterId;
  @Prop({ default: false }) isRequiredParams!: boolean;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly dictionaryType = DictionaryType.EVENT_NAMES;
  localValueParams: Array<EventPickerParamRangeModel> = [];

  get isSegmentPage(): boolean {
    return this.$route.name?.includes("segment") ?? false;
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get names(): Array<Dictionary> {
    return this.dictionaryData.filter(
      (item: Dictionary) =>
        !this.itemsFilter || this.itemsFilter(item, this.value)
    );
  }

  get slotName(): string {
    return this.isAttached ? "append-outer" : "prepend";
  }

  get takenParams() {
    return this.value.params?.map((it) => it.param) || [];
  }

  get localValue(): EventRangePickerModel {
    return this.value;
  }

  get localValueName(): string {
    return this.localValue?.name ?? "";
  }

  set localValueName(value: string) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.name = value;
    this.$emit("input", clonedLocalValue);
  }

  get localValueIncluded(): boolean {
    return this.localValue.included;
  }

  set localValueIncluded(value: boolean) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.included = value;
    this.$emit("input", clonedLocalValue);
  }

  get localValueRange(): RangeModel | undefined {
    return this.localValue?.range;
  }

  set localValueRange(value: RangeModel | undefined) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.range = value as RangeModel;
    this.$emit("input", clonedLocalValue);
  }

  @Watch("localValueParams")
  watchLocalValueParams(value: Array<EventPickerParamRangeModel>) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.params = value;
    this.$emit("input", clonedLocalValue);
  }

  @Watch("localValueName")
  watchName(newValue: string) {
    if (!this.withoutParams) {
      this.localValueParams = [new EventPickerParamRangeModel()];
    }

    this.setUsedDictionaries([newValue]);
  }

  @Watch("value", { immediate: true })
  watchValue(eventRange: EventRangePickerModel) {
    if (
      eventRange.name &&
      !this.names.map(({ value }) => value).includes(eventRange.name)
    ) {
      this.localValueName = "";
    }
  }

  created() {
    this.localValueParams = EventPickerParamRangeModel.ofArray(
      this.value.params.length
        ? this.value.params
        : [new EventPickerParamRangeModel()]
    );
  }

  addParam() {
    this.localValueParams = [
      ...this.localValueParams,
      new EventPickerParamRangeModel(),
    ];
  }

  removeParam(key: string | null) {
    if (!key) {
      return;
    }

    this.localValueParams = this.localValueParams.filter(
      (param) => param.key !== key
    );
  }

  onSearch(value: string | null) {
    const searchValue =
      this.localValueName &&
      this.dictionaryData.find(
        (dictionary) => dictionary.value === this.localValueName
      )?.text === value
        ? null
        : value;

    this.onSearchChange(searchValue);
  }
}
