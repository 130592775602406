
































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep, debounce, difference, xor } from "lodash";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import IncludedIcon from "@/shared/components/IncludedIcon.vue";
import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";
import {
  TrackerPickerModel,
  ValidationRule,
  FilterPreviewId,
  DictionaryType,
  TrackerDictionary,
  TrackerType,
  TrackerOrigin,
} from "@/shared/models";

@Component({
  components: {
    IncludedIcon,
    CustomAutocomplete,
    PickerDictionaryNotification,
  },
})
export default class TrackerPickerInput extends Vue {
  @Prop() value!: TrackerPickerModel;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop() dictionaryType!: TrackerType;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) filterPreviewId!: boolean;
  @Prop() applicationId!: string;
  @Prop({ default: true }) hasSubSource!: boolean;
  @Prop({ default: false }) visible!: boolean;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;
  @Prop() origins?: Array<TrackerOrigin>;

  localValue: Array<string> = this.value[this.dictionaryType];
  search: string | null = null;
  currentSearch: string | null = null;

  get label(): string {
    return this.$lang(`shared.${this.dictionaryType}`);
  }

  get localIncluded(): boolean {
    return this.value.included[this.dictionaryType];
  }

  set localIncluded(newValue: boolean) {
    const tracker = cloneDeep(this.value);

    tracker.included[this.dictionaryType] = newValue;
    this.$emit("input", tracker);
  }

  get localVisible(): boolean {
    return (
      this.dictionaryType === DictionaryType.SOURCES ||
      !!this.search ||
      !!this.items.length
    );
  }

  get dictionaryData(): Array<TrackerDictionary> {
    return this.$store.state.trackerStore.dataTrackers[
      this.dictionaryType
    ].values.filter(
      ({ parentValue }: TrackerDictionary) =>
        !parentValue || this.parentValues.includes(parentValue)
    );
  }

  get isFullResult(): boolean {
    return this.$store.state.trackerStore.dataTrackers[this.dictionaryType]
      .isFullResult;
  }

  get items(): Array<TrackerDictionary> {
    return this.dictionaryData
      .filter((item) => !this.itemsFilter || this.itemsFilter(item, this.value))
      .sort((a: TrackerDictionary, b: TrackerDictionary) => {
        const aSortText = a.text.toLowerCase();
        const bSortText = b.text.toLowerCase();
        const searchText = this.search?.toLowerCase();

        return aSortText.indexOf(searchText) - bSortText.indexOf(searchText);
      });
  }

  get isLoading(): boolean {
    return this.$store.state.trackerStore.loadingTracker[this.dictionaryType];
  }

  get localErrorMessages(): Array<string> {
    return [
      ...this.errorMessages,
      ...(this.localValue.length === this.items.length && !this.value.included
        ? [this.$lang("validation.excludeAllValues")]
        : []),
    ];
  }

  get slotName(): string {
    return this.isAttached ? "append-outer" : "prepend";
  }

  get parentValues(): Array<string> {
    return this.value.getParentValues(this.dictionaryType);
  }

  get localFilterPreviewId(): FilterPreviewId | undefined {
    return this.filterPreviewId
      ? {
          [DictionaryType.SOURCES]: FilterPreviewId.SOURCE,
          [DictionaryType.SUB_SOURCES]: FilterPreviewId.SUB_SOURCE,
          [DictionaryType.CAMPAIGNS]: FilterPreviewId.CAMPAIGN,
          [DictionaryType.AD_SETS]: FilterPreviewId.AD_SET,
          [DictionaryType.CREATIVES]: FilterPreviewId.CREATIVE,
          [DictionaryType.PUBLISHERS]: FilterPreviewId.PUBLISHER,
        }[this.dictionaryType]
      : undefined;
  }

  @Watch("localVisible", { immediate: true })
  watchVisibility(value: boolean) {
    this.$emit("update:visible", value);
  }

  @Watch("value")
  watchValue(newValue: TrackerPickerModel) {
    this.localValue = newValue[this.dictionaryType];
  }

  @Watch("localValue")
  watchValues(newValues: Array<string>, oldValues: Array<string> | undefined) {
    this.setUsedDictionaries(newValues, oldValues);

    if (
      [
        DictionaryType.SOURCES,
        DictionaryType.CAMPAIGNS,
        DictionaryType.AD_SETS,
      ].includes(this.dictionaryType)
    ) {
      return;
    }

    this.updateTrackerValue();
  }

  @Watch("items")
  watchItems(items: Array<TrackerDictionary>) {
    const availableValues = items.map(({ value }) => value);

    if (this.localValue.some((value) => !availableValues.includes(value))) {
      const tracker = cloneDeep(this.value);

      tracker[this.dictionaryType] = [];
      this.$emit("input", tracker);
    }
  }

  @Watch("search")
  watchSearch = debounce(this.initSearch, 500);

  initSearch(search: string | null) {
    if (
      (this.isFullResult && !this.currentSearch) ||
      this.currentSearch === search ||
      (!this.currentSearch && (!search || search.length < 2))
    ) {
      return;
    }

    this.currentSearch = search && search.length > 1 ? search : null;
    this.$store.dispatch("loadTracker", {
      applicationId: this.applicationId,
      type: this.dictionaryType,
      search: this.currentSearch,
      parentValues: this.parentValues,
      origins: this.origins,
    });
  }

  onSearchChange(value: string | null) {
    this.search = value;
  }

  setUsedDictionaries(newValues: Array<string>, oldValues: Array<string> = []) {
    const valuesToAdd = difference(newValues, oldValues);

    if (!valuesToAdd.length) {
      return;
    }

    this.$store.commit("setUsedTrackerDictionaries", {
      [this.dictionaryType]: {
        dictionaryValues: this.dictionaryData.filter(({ value }) =>
          valuesToAdd.includes(value)
        ),
      },
    });
  }

  onBlur() {
    if (
      [
        DictionaryType.SUB_SOURCES,
        DictionaryType.CREATIVES,
        DictionaryType.PUBLISHERS,
      ].includes(this.dictionaryType)
    ) {
      return;
    }

    this.updateTrackerValue();
  }

  updateTrackerValue() {
    if (!xor(this.value[this.dictionaryType], this.localValue).length) {
      return;
    }

    const tracker = cloneDeep(this.value);

    tracker[this.dictionaryType] = this.localValue;
    this.$emit("input", tracker);
  }
}
