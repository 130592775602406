





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class IncludedIcon extends Vue {
  @Prop({ default: true }) value!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) small!: boolean;

  localValue = true;

  @Watch("value", { immediate: true })
  watchValue(value: boolean) {
    this.localValue = value;
  }

  @Watch("localValue")
  private watchLocalValue(value: boolean) {
    this.$emit("input", value);
  }

  get dark() {
    return this.$vuetify.theme.dark;
  }
}
